
export default function Navigation({ setMode }) {
    return (
        <nav className="navigation">
            <img className="logo" src="krumware-logo-with-name.svg" alt="Krumware Logo" />
            <div className="spacer"></div>
            <div>
                <button className="nav-button" onClick={() => setMode('openaichat')}>OpenAI Chat</button>
                <button className="nav-button" onClick={() => setMode('llamachat')}>Local Llamas (maybe with hats?)</button>
            </div>
        </nav>
    );
}
