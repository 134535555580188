import React, { useState } from 'react';
import axios from 'axios';

import './App.css';
import Navigation from './components/navigation';
import Messages from './components/messages';
import Chat from './components/chat';

function App() {
  // const url = 'https://epinio-ai-john-20241027-02.epinio.krum-dev.cloud.krum.io';
  const url = 'http://localhost:8080';
  const [mode, setMode] = useState('openaichat');
  const [messages, setMessages] = useState([]);

  const sendMessage = async (message) => {
    const updatedMessages = [...messages, message];
    setMessages(updatedMessages);
    try {
      const response = await axios.post(
        `${url}/${mode}`,
        { messages: updatedMessages },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response && response.data) {
        setMessages(response.data);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <Navigation setMode={setMode}/>
      </header>
      <main className="main">
        <Messages messages={messages} mode={mode} />
      </main>
      <footer className="footer">
        <Chat sendMessage={sendMessage} />
      </footer>
    </div>
  );
}

export default App;
