import React from "react";

export default function Messages({ messages, mode }) {
    return (
        <div className="messages">
            <p className="mode-display">Mode: {mode}</p>
            {messages.map((message, i) => (
                message.role !== 'system' && <p className="message" key={i}><em>{message.role}</em>: {message.content}</p>
            ))}
        </div>
    );
}
