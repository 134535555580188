import React from 'react';

export default function Chat({ sendMessage }) {
    const [message, setMessage] = React.useState('');

    const handleMessageSent = () => {
        if (message !== '') {
            sendMessage({ role: 'user', content: message });
            setMessage('');
        }
    };
    return (
        <div className="chat">
            <textarea
                className="chat-textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)} 
            />
            <button
                className="send-button"
                onClick={() => handleMessageSent()}
            >
                Send
            </button>
        </div>
    );
}
